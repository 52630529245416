.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.redRow {
  background-color: #dd6a6a1c !important;
}
.blueRow {
  background-color: #6a89dd1c !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.gray-small-scroll::-webkit-scrollbar {
  width: 6px;
}

.gray-small-scroll::-webkit-scrollbar-track {
  background: #ecebf8;
  padding-left: 15px;
}

.gray-small-scroll::-webkit-scrollbar-thumb {
  background: #272f44;
}
.help-page section {
  scroll-snap-align: start;
}
*[title-data] {
  /* required */
  position: relative;
  /* decorative */
  cursor: help;
}

*[title-data]:hover::after {
  /* required */
  content: attr(title-data);
  position: absolute;
  background-color: hsla(130, 15%, 95%, 1);
  height: fit-content;
  width: max-content;
  z-index: 99;
  /* decorative */
  padding: 0.5em;
  border-radius: 0.5em;
  border-width: 1px;
  opacity: 96%;
  border-color: #000c18;
  inset: 0em 0.5em 0em 0.5em;
  font-size: 0.9rem;
  font-weight: 300;
  font-style: italic;
  max-width: 20em;
}
.fullWidthCell {
  border-top: none;
  border-bottom: none;
}
