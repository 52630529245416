@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
body {
  margin: 0;
  font-family: 'Fira Sans Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Manrope', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
.md-custom p {
  white-space: pre-wrap;
}
.contextContainer {
  z-index: 1;
  width: 100%;
  height: 500px;
  background: #673ab7;
}
.rightClick {
  z-index: 12;
  position: fixed;
  background: rgb(240, 200, 1);
}
.menuElement {
  color: #222222;
  cursor: pointer;
  padding: 17px 36px;
}
.menuElement:hover {
  color: #fff;
  background: #009688;
}
.custom-toast::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent black overlay */
  backdrop-filter: blur(2px); /* Blur effect */
  z-index: -1; /* Position behind the content */
}
@tailwind base;
@tailwind components;
@tailwind utilities;
